import React from 'react'
import {
  HashRouter as Router,
  Route,
  Switch,
} from 'react-router-dom'
import { GlobalStyle } from './components/GlobalStyle'
import { HomePage } from './pages/Home'
import { QueryPage } from './pages/Query'
import { PageMark } from './constants/pageMark'
import { RootProvider } from './context/Root'
import { HomeProvider } from './context/Home'
import { QueryProvider } from './context/Query'

const App = () => (
  <Router>
    <GlobalStyle />
    <RootProvider>
      <Switch>
        <Route exact path={PageMark.HOME}>
          <HomeProvider>
            <HomePage />
          </HomeProvider>
        </Route>
        <Route path={PageMark.QUERY}>
          <QueryProvider>
            <QueryPage />
          </QueryProvider>
        </Route>
      </Switch>
    </RootProvider>
  </Router>
)
export default App
