import React, { useMemo, useCallback } from 'react'
import { InputProps } from '../type/common'
import useBoolean from './useBoolean'
import useNumber from './useNumber'
import { useValue } from './useValue'

type Rule = {
    maxLength?: number,
    minLength?: number,
    regexp?: RegExp,
    test?: (value: string) => boolean,
}
type InputRule = Rule
type InputRuleTester = ((value: string) => boolean) | InputRule
type RestrictRule = Omit<Rule, 'minLength'>
type RestrictRuleTester = ((value: string) => boolean) | RestrictRule

const createTest = (rule?: ((value: string) => boolean) | Rule) => (value: string) => {
    if (!rule) return true

    if (typeof rule === 'function') return rule(value)

    return (
        (rule.maxLength === undefined || value.length <= rule.maxLength)
        && (rule.minLength === undefined || value.length >= rule.minLength)
        && (rule.regexp === undefined || rule.regexp.test(value))
        && (rule.test === undefined || rule.test(value))
    )
}

export const useInput = ({ rule, restrict }: { rule?: InputRuleTester, restrict?: RestrictRuleTester }, inputProps: InputProps = {}) => {
    const validRuleTest = useMemo(() => createTest(rule), [rule])
    const restrictRuleTest = useMemo(() => createTest(restrict), [restrict])
    const valueActions = useValue((inputProps.value || '').toString())
    const valid = useMemo(
        () => validRuleTest(valueActions.value),
        [validRuleTest, valueActions.value],
    )
    const focussedActions = useBoolean(false)
    const timeStampActions = useNumber(() => Date.now())
    const inputOnChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value

        if (!restrictRuleTest(value) && value !== '') return

        valueActions.set(e.target.value)
        timeStampActions.set(Date.now())
        inputProps.onChange?.(e)
    }, [inputProps, restrictRuleTest, timeStampActions, valueActions])

    const inputOnFocus = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
        focussedActions.setTrue()
        inputProps.onFocus?.(e)
    }, [focussedActions, inputProps])

    const inputOnBlur = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
        focussedActions.setFalse()
        inputProps.onBlur?.(e)
    }, [focussedActions, inputProps])

    return useMemo(() => ({
        value: valueActions.value,
        focussed: focussedActions.value,
        timeStamp: timeStampActions.value,
        valid,
        inputProps: {
            ...inputProps,
            value: valueActions.value,
            onChange: inputOnChange,
            onFocus: inputOnFocus,
            onBlur: inputOnBlur,
        }
    }), [focussedActions.value, inputOnBlur, inputOnChange, inputOnFocus, inputProps, timeStampActions.value, valid, valueActions.value])
}

export type UseInputReturns = ReturnType<typeof useInput>
