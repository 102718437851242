import { useCallback, useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router-dom"
import { PageMark } from "../constants/pageMark"
import { useInput } from "../hooks/useInput"
import { createUseContext } from '../lib/createUseContext'
import { login, getTipAlert } from "../service/common"
import { useRootContext } from "./Root"

export const [HomeProvider, useHomeContext] = createUseContext(() => {
  const { expressInfo } = useRootContext()

  const history = useHistory()

  const nameInputActions = useInput({ rule: { minLength: 2 } })
  const phoneActions = useInput({ rule: { minLength: 11, maxLength: 11, regexp: /^\w+$/ }, restrict: { maxLength: 11, regexp: /^\d+$/ } })
  const inputValid = useMemo(() => nameInputActions.valid && phoneActions.valid, [nameInputActions, phoneActions])

  const validateCouponInfo = useCallback(async (mustUnFilled: boolean, successHandler: () => void) => {
    const loginInfoResponse = await login(nameInputActions.value, phoneActions.value)

    if (loginInfoResponse.success) {
      expressInfo.set(loginInfoResponse.data)

      successHandler()
    } else {
      alert(loginInfoResponse.msg)
    }
  }, [nameInputActions.value, expressInfo, phoneActions.value])

  const [alertContent, setAlertContent] = useState('')

  useEffect(
    () => {
      getTipAlert().then(response => {
        if (response.success) {
          setAlertContent(response.data)
        }
      })
    },
    []
  )

  const [showAlert, setShowAlert] = useState(false)

  const checkBeforeSubmitLogin = useCallback(
    () => {
      setShowAlert(true)
    },
    []
  )

  const submitLogin = useCallback(
    () => {
      setShowAlert(false)

      return validateCouponInfo(true, () => {
        history.push(PageMark.PRIZE)
      })
    },
    [history, validateCouponInfo]
  )
  const submitQuery = useCallback(
    () => validateCouponInfo(false, () => {
      history.push(PageMark.QUERY)
    }),
    [history, validateCouponInfo]
  )

  return {
    nameInputProps: nameInputActions.inputProps,
    phoneInputProps: phoneActions.inputProps,
    inputValid,
    showAlert,
    checkBeforeSubmitLogin,
    submitLogin,
    submitQuery,
    alertContent,
  }
})
