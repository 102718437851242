import { useCallback, useMemo } from 'react';
import { useValue } from './useValue';

export function useNumber(initial: number | (() => number)) {
  const valueActions = useValue(initial);
  const inc = useCallback((n = 1) => valueActions.set(v => v + n), [
    valueActions,
  ]);
  const dec = useCallback((n = 1) => valueActions.set(v => v - n), [
    valueActions,
  ]);

  return useMemo(() => ({ ...valueActions, inc, dec }), [
    valueActions,
    inc,
    dec,
  ]);
}

export default useNumber;
