import { css, keyframes } from "styled-components"

export const fullSize = css`
    width: 100%;
    height: 100%;
`

export const ghost = css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`

export const verticalCenter = css`
    display: flex;
    align-items: center;
`
export const horizontalCenter = css`
    display: flex;
    justify-content: center;
`
export const bothCenter = css`
    display: flex;
    align-items: center;
    justify-content: center;
`

export const blinkAnimation = keyframes`
    0% { opacity: 1; }
    50% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 0; }
`

export const blinkLine = css`
  &::after {
    content: '';
    height: 1.5em;
    width: 2px;
    background-color: #000;
    transform: scaleX(0.5);
    animation: ${blinkAnimation} 1s linear infinite;
  }`
