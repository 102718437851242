import { useSetState } from "../hooks/useSetState"
import { createUseContext } from '../lib/createUseContext'

export type ExpressInfo = {
  error: boolean,
  expressCompany: string;
  expressSn: string;
  gift: string;
}

export const [RootProvider, useRootContext] = createUseContext(() => {
  const expressInfo = useSetState<ExpressInfo>({
    error: false,
    expressCompany: '',
    expressSn: '',
    gift: '',
  })

  return {
    expressInfo,
  }
})
