import React from 'react'
import { Page } from '../components/Page';
import { Image } from '../components/Image';
import page1 from '../assets/page1.jpg'
import { Content } from '../components/Content';
import { LayoutRow } from '../components/LayoutRow';
import { Input } from '../components/Input';
import { Button } from '../components/Button';
import { Modal } from '../components/Modal';
import { useHomeContext } from '../context/Home';

const heightOffset = 40

export const HomePage = () => {
  const { nameInputProps, phoneInputProps, inputValid, showAlert, submitLogin, submitQuery, alertContent } = useHomeContext()

  return (
    <Page>
      <Image src={page1} alt="兑换奖品展示" />
      <Content>
        <LayoutRow top={714 + heightOffset} height={72}>
          <Input
            {...nameInputProps}
            placeholder="请输入姓名（一个字以上）"
          />
        </LayoutRow>
        <LayoutRow top={836 + heightOffset} height={72}>
          <Input
            {...phoneInputProps}
            placeholder="请输入联系电话"
            pattern="[\d]{11}"
            title="11位数字电话"
          />
        </LayoutRow>
        <LayoutRow width={700} paddingLeft={0} paddingRight={0} top={942 + heightOffset} height={88} space="center">
          <Button
            width={340}
            variant={inputValid ? 'active' : 'disable'}
            onClick={submitQuery}
          >确认查询</Button>
        </LayoutRow>
      </Content>
      <Modal
        visible={showAlert && !!alertContent}
        Button={<Button width={340} variant="active" onClick={submitLogin}>确认</Button>}>
        {alertContent}
      </Modal>
    </Page>
  )
}

