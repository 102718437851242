import { useCallback } from "react"
import { useHistory } from "react-router-dom"
import { createUseContext } from '../lib/createUseContext'

export const [QueryProvider, useQueryContext] = createUseContext(() => {
  const history = useHistory()

  const gotoHome = useCallback(() => { history.goBack() }, [history])

  return {
    gotoHome,
  }
})
