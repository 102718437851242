import React from 'react'
import { Page } from '../components/Page';
import { Image } from '../components/Image';
import { Content } from '../components/Content';
import { Button } from '../components/Button';
import page6 from '../assets/page6.jpg'
import styled from 'styled-components'
import { px2vw } from '../utils/px2vw';
import { LayoutRow } from '../components/LayoutRow';
import { useQueryContext } from '../context/Query';
import { useRootContext } from '../context/Root';

const TopOffset = 906

const Text = styled.p`
  color: #40a5fe;
  font-size: ${px2vw(42)};
  font-weight: 600;
  line-height: ${px2vw(64)};
  margin: 0;
`
const Gift = styled.p`
  color: #40a5fe;
  font-size: ${px2vw(42)};
  font-weight: 600;
  line-height: ${px2vw(64)};
  max-width: ${px2vw(720)};
  margin: 0;
  word-break: break-all;
  text-align: center;
`

const FormRow = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: ${px2vw(TopOffset - 644)};
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  padding-left: ${px2vw(40)};
  padding-right: ${px2vw(40)};
  padding-top: ${px2vw(20)};
  padding-bottom: ${px2vw(40)};
  font-size: ${px2vw(20)};
  line-height: ${px2vw(26)};
  width: ${px2vw(670)};
  box-sizing: border-box;
`

export const QueryPage = () => {
  const { expressInfo } = useRootContext()
  const { gotoHome } = useQueryContext()

  return (
    <Page>
      <Image src={page6} alt="兑换奖品展示" />
      <Content>
        <FormRow>
          {expressInfo.value.error ? (
            <Text>未查询到数据</Text>
          ) : (
            <>
              <Gift>{expressInfo.value.gift}</Gift>
              <Text>{expressInfo.value.expressCompany}</Text>
              <Text>{expressInfo.value.expressSn}</Text>
            </>
          )}
        </FormRow>
        <LayoutRow top={TopOffset + 50} height={72} space="center">
        <Button variant="active" onClick={gotoHome} width={220} height={60}>返回</Button>
      </LayoutRow>
      </Content>
    </Page>
  )
}

