import React from 'react'
import styled from "styled-components"
import { px2vw } from "../utils/px2vw"
import { fullSize, verticalCenter } from '../style/common'
import { InputProps } from '../type/common'

const InputWrapper = styled.div<{ darkColor?: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;
  background: ${props => props.darkColor ? '#98b4dc' : '#98b4dc'};
  box-sizing: border-box;
  border-radius: ${px2vw(10)};
  overflow: hidden;

  input {
    ${verticalCenter}
    ${fullSize}
    background: transparent;
    border: none;
    outline: none;
    padding-left: ${px2vw(20)};
    padding-right: ${px2vw(20)};
    font-size: ${px2vw(32)};
    box-sizing: border-box;
    color: #fff;

    &::placeholder {
      color: #fff;
    }
  }
`

export const Input = (inputProps: InputProps & { darkColor?: boolean }) => {
  return (
    <InputWrapper darkColor={inputProps.darkColor}>
      <input {...inputProps} autoComplete="close" />
    </InputWrapper>
  )
}
