import styled from "styled-components"
import { px2vw } from "../utils/px2vw"

export type LayoutRowProps = {
  space?: string,
  top: number, height: number,
  paddingLeft?: number,
  paddingRight?: number,
  width?: number,
}

export const LayoutRow = styled.div<LayoutRowProps>`
  display: flex;
  position: absolute;
  justify-content: ${({ space }) => space};
  top: ${({ top }) => px2vw(top)};
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  padding-left: ${({ paddingLeft = 60 }) => px2vw(paddingLeft)};
  padding-right: ${({ paddingRight = 60 }) => px2vw(paddingRight)};
  width: ${({ width = 560 }) => px2vw(width)};
  height: ${({ height }) => px2vw(height)};
  box-sizing: border-box;
`
