import React from 'react'
import styled from 'styled-components';
import { px2vw } from '../utils/px2vw';

const Mask = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => visible ? 'flex' : 'none' };
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
`

const Main = styled.div`
  max-width: 80%;
  max-height: 80%;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: ${px2vw(28)};
  min-width: ${px2vw(340)};
  overflow: hidden;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
`
const Content = styled.div`
`

const ButtonWrapper = styled.div`
  flex-shrink: 0;
  width: 100%;
  height: ${px2vw(60)};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${px2vw(10)} 0;
`

export type ModalProps = {
  visible: boolean,
  children: string,
  Button: React.ReactNode,
}
export const Modal = React.memo<ModalProps>(({ visible, children, Button }) => {

  return (
    <Mask visible={visible}>
      <Main>
        <ContentWrapper>
          <Content>
            <div dangerouslySetInnerHTML={{__html: children}} />
          </Content>
        </ContentWrapper>
        <ButtonWrapper>
          {Button}
        </ButtonWrapper>
      </Main>
    </Mask>
  )
})
