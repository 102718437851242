import styled from 'styled-components'
import { bothCenter } from '../style/common'
import { px2vw } from '../utils/px2vw'

type ButtonVariant = 'normal' | 'active' | 'default' | 'disable' | 'ghost'

const ButtonColorMap: Record<ButtonVariant, string> = {
  'normal': '#fff', 'active': '#fff', 'default': '#3a4664', 'disable': '#fff', 'ghost': 'transparent'
}
const ButtonBackgroundMap: Record<ButtonVariant, string> = {
  'normal': '#3a4664', 'active': 'linear-gradient(to left, rgb(40 120 252), rgb(60 150 252))', 'default': '#fff', 'disable': 'grey', 'ghost': 'transparent'
}

export const Button = styled.div<{ width: number, height?: number, variant?: ButtonVariant }>`
  ${bothCenter}

  pointer-events: ${({ variant }) => variant === 'disable' ? 'none' : null};
  cursor: pointer;

  width: ${({ width }) => px2vw(width)};
  height: ${({ height }) => height ? px2vw(height) : '100%'};
  box-sizing: border-box;
  margin-left: ${px2vw(10)};
  margin-right: ${px2vw(10)};

  letter-spacing: 0.1em;
  font-size: ${px2vw(30)};
  color: ${({ variant = 'default' }) => ButtonColorMap[variant]};
  background: ${({ variant = 'default' }) => ButtonBackgroundMap[variant]};
  border-radius: 2em;
  font-weight: 600;
/* 
  color: transparent;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none; */
`
