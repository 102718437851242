import React, { useContext } from 'react'

export const createUseContext = function <T>(contextFactory: () => T) {
  const Context = React.createContext<T | null>(null)

  const Provider: React.FC = ({ children }) => {
    const context = contextFactory()

    return (
      <Context.Provider value={context}>{children}</Context.Provider>
    )
  }

  const useStateContext = () => {
    const context = useContext(Context)

    if (context === null) throw Error('Wrap topper components with Provider')

    return context
  }

  return [Provider, useStateContext] as const
}
