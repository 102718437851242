import { useCallback, useMemo, useState } from "react";

export type UseValueReturns<T> = {
  value: T,
  set: (newValue: T | ((v: T) => T)) => void,
  reset: () => void
};

export const useValue = <T>(initialValue:  T | (() => T)): UseValueReturns<T> => {
  const [value, setValue] = useState(initialValue);

  const set = setValue
  const reset = useCallback(() => set(initialValue), [set, initialValue]);

  return useMemo(() => ({
    value,
    set,
    reset,
  }), [value, set, reset])
}
