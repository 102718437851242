import { useCallback, useMemo } from 'react';
import { useValue } from './useValue';

export const useBoolean = (initial: boolean) => {
  const valueActions = useValue(initial);
  const toggle = useCallback(() => valueActions.set(v => !v), [
    valueActions,
  ]);
  const setTrue = useCallback(() => valueActions.set(true), [
    valueActions,
  ]);
  const setFalse = useCallback(() => valueActions.set(false), [
    valueActions,
  ]);

  return useMemo(() => ({ ...valueActions, toggle, setTrue, setFalse }), [
    valueActions,
    toggle,
    setTrue,
    setFalse,
  ]);
}

export default useBoolean;
