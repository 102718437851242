import unfetch from "unfetch"

type Success<T> = {
  success: true,
  data: T
}

type Failure = {
  success: false,
  msg: string,
}

type Result<T> = Success<T> | Failure

// const serverHost = window.location.host
const serverHost = window.location.host.replace(/^[\w]+/, ($0) => $0 + '-admin')
// const serverHost = 'guangdameiyun-admin.gookoo.net'

const EndPoints = {
  TipAlert: `//${serverHost}/api/index`,
  Login: `//${serverHost}/api/index/login`,
  CreateAddress: `//${serverHost}/api/index/create_address`,
  GetCouponStatus: `//${serverHost}/api/index/get_coupon_status`,
}

export const getTipAlert = async (): Promise<Result<string>> => {
  const response = await unfetch(EndPoints.TipAlert, {
    method: 'GET',
  }).catch(e => null)

  if (!response) {
    return { success: true, data: '' }
  }

  type ResponseInfo = {
    code: 0 | 1,
    msg: string,
    data: { content: string }
  }

  const { code, data }: ResponseInfo = await response.json().catch(() => ({ code: 0, msg: '请求错误，请稍后再试' }))

  if (code !== 1) {
    return {
      success: true,
      data: '',
    }
  }

  return {
    success: true,
    data: data.content,
  }
}

export const login = async (name: string, mobile: string): Promise<Result<{
  error: boolean
  expressCompany: string
  expressSn: string
  gift: string
}>> => {
  const formData = new FormData();
  formData.append('name', name);
  formData.append('mobile', mobile);

  const response = await unfetch(EndPoints.Login, {
    method: 'POST',
    body: formData,
  }).catch(e => null)

  if (!response) {
    return { success: false, msg: '网络开小差，请稍后再试' }
  }

  type ResponseInfo = {
    code: 0 | 1 | 2,
    msg: string,
    data: {
      express_company: string,
      express_sn: string,
      gift: string,
    }
  }

  const { code, data }: ResponseInfo = await response.json().catch(() => ({ code: 0, msg: '请求错误，请稍后再试' }))

  if (code !== 1) {
    return {
      success: true,
      data: {
        error: true,
        expressCompany: '',
        expressSn: '',
        gift: '',
      }
    }
  }

  return {
    success: true,
    data: {
      error: false,
      expressCompany: data.express_company,
      expressSn: data.express_sn,
      gift: data.gift,
    },
  }
}
