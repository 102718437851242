import { useCallback, useMemo, useState } from "react"

export type UseStateReturns<S> = {
  value: S,
  set: (partialState: Partial<S>) => void,
  reset: () => void
}

export type UseState = <S extends Record<string, any>>(state: S) => UseStateReturns<S>

export const useSetState: UseState = <S extends Record<string, any>>(
  initialState: S,
) => {
  const [value, setValue] = useState(initialState)
  const set = useCallback((s: Partial<S>) => {
    setValue((completedValue: S) => ({ ...completedValue, ...s }))
  }, [])
  const reset = useCallback(() => { set(initialState) }, [initialState, set])

  return useMemo(() => ({
    value,
    set,
    reset,
  }), [value, set, reset])
}
